import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "bulma/css/bulma.css"
import "../styles/promocje.scss"
import Footer from "../components/footer"
import _navigation from "../components/_navigation"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ArrowBack from "@material-ui/icons/ArrowBack"

const Promocje = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPromocje {
        edges {
          node {
            tytul
            childContentfulPromocjeTrescRichTextNode {
              json
            }
            zdjecie {
              resize {
                src
              }
            }
          }
        }
      }
    }
  `)
  const options = {
    renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }
  return (
    <div className="promocje-main-container">
      <_navigation />
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">Promocje</h1>
          </div>
        </div>
      </section>
      <div className="container is-flex-column ">
        {data &&
          data.allContentfulPromocje.edges.map(promocja => (
            <div className="single-promocja-wrapper columns ">
              <img
                alt="grafika promocyjna"
                className="promocja-fota is-4by3 column is-3 is-hidden-mobile"
                style={{ maxHeight: "300px" }}
                src={
                  promocja &&
                  promocja.node &&
                  promocja.node.zdjecie &&
                  promocja.node.zdjecie.resize &&
                  promocja.node.zdjecie.resize.src
                }
              />
              <div className="column is-offset-1 content-promocja-wrapper">
                <span className="single-promocja-tytul">{promocja.node.tytul}</span>
                <span>
                  {documentToReactComponents(promocja.node.childContentfulPromocjeTrescRichTextNode.json, options)}
                </span>
              </div>
            </div>
          ))}
      </div>
      <div className="button-wrapper-wstecz">
        <Link to={"/"}>
          <button className="button button-wstecz-promocje is-primary is-light">
            <ArrowBack /> Wstecz
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  )
}
export default Promocje
